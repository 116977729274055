import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
if (document.querySelector('.opinions-slider')) {
    const swiper = new Swiper('.opinions-slider .swiper', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        speed: 700,
        spaceBetween: 16,
        slidesPerView: 1,
        breakpoints: {
            1024: {
              slidesPerView: 2,
            },
        },
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        
    });
}
