import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

document.querySelectorAll('.home-slider').forEach((slider, index) => {

    const swiper = new Swiper(slider.querySelector('.swiper'), {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        speed: 700,
        slidesPerView: 1,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: '.swiper-next-btn',
            prevEl: '.swiper-prev-btn',
        },
        pagination: {
            el: ".swiper-pagination",
          },
    });
});
