const togglePassword = document.querySelector('#toggle-password');

if (togglePassword) {
    const password = togglePassword.parentElement.querySelector('#reg_password');

    if (password) {
        togglePassword.addEventListener('click', () => {
            password.type = password.type === 'password' ? 'text' : 'password';
        });
    }
}
