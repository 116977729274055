import './ajax-add-to-cart';
import './header';
import './navigation';
import './toggle-password';

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
if (document.querySelector('.opinions-slider')) {
    const swiper = new Swiper('.opinions-slider .swiper', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        speed: 700,
        slidesPerView: 1,
        breakpoints: {
            1024: {
                slidesPerView: 2,
                spaceBetween: 32,
            },
        },
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
    });
}
