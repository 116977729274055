const listItems = document.querySelectorAll('li.menu-item-has-children a');
const goBackBtns = document.querySelectorAll('.Navigation__subMenu__go-back');

const query = window.matchMedia('(max-width: 1024px)');

const initSubMenuFunctionality = () => {
    goBackBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
            const parentUl = btn.closest('ul');
            parentUl.classList.remove('open');
        });
    });

    // const handleSubMenu = (ev) => {
    //     ev.preventDefault();
    //     const clickedLink = ev.currentTarget;
    //     const parent = clickedLink.closest('li');
    //     const nestedMenu = parent.querySelector('ul');
    //     nestedMenu.classList.add('open');
    // };

    // listItems?.forEach((listItem) => listItem.addEventListener('click', handleSubMenu));
};

if (query.matches) {
    initSubMenuFunctionality();
}

const handleMenuMobile = (param) => {
    if (param.matches) {
        initSubMenuFunctionality();
    }
};

query.addEventListener('change', handleMenuMobile);


// Mega menu

const mega_menu = document.querySelectorAll('.mega_menu');
if (mega_menu.length > 0) {
    function updateStyles() {
        const megaMenu__wrapper = document.querySelector('.megaMenu__wrapper');
        const Header = document.querySelector('.megaMenu__wrapper').offsetWidth;

        mega_menu.forEach((menu) => {
            const wrapperPosition = menu.getBoundingClientRect();
            const differenceFromLeft = wrapperPosition.left;
            const actualInnerWidth = document.body.offsetWidth;

            const child = menu.querySelector('.Navigation__subMenu');
            const width = megaMenu__wrapper.offsetWidth;


            if (window.innerWidth > 1023) {
                const paddingValue = `calc((${actualInnerWidth}px - ${width}px) / 2)`;
                child.style.width = Header + 'px';
                // child.style.left = `-${differenceFromLeft}px`;
                // child.style.paddingLeft = `${paddingValue}`;
                // child.style.paddingRight = `${paddingValue}`;
            } else {
                child.style.width = "100%";
            }
        });

    }

    window.addEventListener('load', updateStyles);
    window.addEventListener('resize', updateStyles);
}



mega_menu.forEach((menu) => {

    const mega_menuhref = menu.querySelector(':scope > a');

    mega_menuhref.addEventListener('click', () => {
        mega_menuhref.parentElement.classList.toggle('active');
      }); 
});




document.addEventListener('DOMContentLoaded', () => {
    const expand_submenus = document.querySelectorAll('.expand_submenu');
    const expand_submenu__mores = document.querySelectorAll('.expand_submenu__more a');

    expand_submenu__mores.forEach((expand_submenu__more, index) => {
        expand_submenu__more.addEventListener('click', (event) => {
            event.preventDefault(); 
            event.stopPropagation(); 
            expand_submenus[index].classList.toggle('active');
            console.log('Click event triggered for submenu ' + index);
        });
    });
});



function updateLinks() {
    const links = document.querySelectorAll('.mega_menu > a');
    if (window.innerWidth <= 1023) {
      links.forEach(function(link) {
        link.setAttribute('href', '#');
      });
    } else {
      // Opcjonalnie przywróć oryginalne href, jeśli szerokość jest większa niż 1023px
      links.forEach(function(link) {
        link.setAttribute('href', link.getAttribute('data-original-href'));
      });
    }
  }
  
  document.addEventListener('DOMContentLoaded', function() {
    // Zapisz oryginalne href w data attribute
    const links = document.querySelectorAll('.mega_menu > a');
    links.forEach(function(link) {
      link.setAttribute('data-original-href', link.getAttribute('href'));
    });
  
    // Zaktualizuj linki przy załadowaniu strony
    updateLinks();
  
    // Zaktualizuj linki przy zmianie rozmiaru okna
    window.addEventListener('resize', updateLinks);
  });
  