import $ from 'jquery';

const openCartButton = $('#open-cart-btn');
const closeCartButton = $('.SidebarCart__close');
const sidebarCart = $('.SidebarCart');

const handleOpenCart = (ev) => {
    ev.preventDefault();
    sidebarCart.addClass('active');
};

const handleCloseCart = (ev) => {
    ev.preventDefault();
    sidebarCart.removeClass('active');
};

openCartButton.on('click', handleOpenCart);

closeCartButton.on('click', handleCloseCart);

const handleRemoveItemFromCart = (ev) => {
    ev.preventDefault();
    sidebarCart.addClass('is-loading');
    const productKey = $(ev.target).attr('data-key');

    $.ajax({
        type: 'GET',
        url: jsData.ajaxUrl,
        data: {
            action: 'remove_item_from_cart',
            productKey,
        },
        success: (response) => {
            $(document.body).trigger('updated_cart_totals');
        },
    });
};

$(document).on('click', 'button.remove', handleRemoveItemFromCart);

const updateCartItems = () => {
    sidebarCart.addClass('is-loading');
    $.ajax({
        type: 'GET',
        url: jsData.ajaxUrl,
        data: {
            action: 'kreacja_get_cart_items',
        },
        success: (response) => {
            sidebarCart.removeClass('is-loading');
            sidebarCart.find('#sidebar-cart-count').html(response.cart_count);
            sidebarCart.find('#sidebar-cart-total').html(response.cart_total);
            sidebarCart.find('.SidebarCart__content').html(response.cart_items);
            if (response.cart_count > 0) {
                $('#menu-cart-count').html(response.cart_count).show();
            } else {
                $('#menu-cart-count').hide();
            }
        },
    });
};

$(document.body).on('added_to_cart updated_cart_totals wc_cart_emptied', updateCartItems);
