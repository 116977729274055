// import axios from 'axios';
// import { clearConfigCache } from 'prettier';

// const getSearchLength = (data) => {
//     let dataLength = 0;
//     data.forEach((item) => {
//         if (item.data.length) {
//             dataLength += 1;
//         }
//     });

//     return dataLength;
// };

// export default class SearchForm {
//     constructor() {
//         this.container = document.querySelector('.SearchPopup');
//         this.form = this.container.querySelector('form');
//         this.serchTerm = null;
//         this.timeout = null;
//         this.openBtn = document.querySelector('#open-search-popup');
//         this.closeBtn = document.querySelector('#close-search-popup');
//         this.resultsContainer = this.container.querySelector('.SearchPopup__results .wrapper');
//         this.loader = this.container.querySelector('.Loader');
//         this.init();
//         this.isLoading = false;
//     }

//     init() {
//         this.openBtn.addEventListener('click', (e) => {
//             e.preventDefault();

//             document.documentElement.style.overflowY = 'hidden';

//             this.container.classList.add('open');
//             setTimeout(() => {
//                 document.querySelector('.s-input').focus();
//             }, 100);
//         });

//         this.closeBtn.addEventListener('click', (e) => {
//             e.preventDefault();
//             document.documentElement.style.overflowY = 'auto';
//             this.container.classList.remove('open');
//         });

//         this.form.addEventListener('input', (e) => {
//             if (this.isLoading) return;
//             this.searchTerm = e.target.value;
//             clearTimeout(this.timeout);
//             if (this.searchTerm.length > 2) {
//                 this.resultsContainer.innerHTML = '';
//                 this.showLoader();
//                 this.timeout = setTimeout(() => {
//                     this.isLoading = true;
//                     this.getData(
//                         `${jsData.siteUrl}/wp-json/kreacja/v1/search?key=${this.searchTerm}`,
//                     );
//                 }, 1000);
//             }
//         });
//     }

//     async getData(url) {
//         const options = {
//             method: 'GET',
//             url,
//         };

//         axios
//             .request(options)
//             .then((response) => {
//                 const length = getSearchLength(response.data);
//                 if (length > 0) {
//                     this.buildList(response.data);
//                 } else {
//                     this.nothingFound();
//                 }
//                 this.isLoading = false;
//             })
//             .catch(function (error) {
//                 console.error(error);
//             });
//     }

//     buildList(data) {
//         const typeArrays = Object.values(data);
//         typeArrays.forEach((type) => {
//             if (type.data.length > 0) {
//                 const container = document.createElement('div');
//                 const heading = document.createElement('h4');
//                 container.classList.add('search-results');
//                 heading.innerText = type.heading;
//                 heading.className = 'mb-4';
//                 container.appendChild(heading);

//                 type.data.forEach((item) => {
//                     const itemEl = document.createElement('a');

//                     const { permalink, price, name, images } = item;

//                     itemEl.innerHTML +=
//                         images !== undefined ? `<img src='${images.thumbnail}' />` : ``;

//                     itemEl.innerHTML += `<span class='name'>${name}</span>`;

//                     itemEl.innerHTML +=
//                         price !== undefined ? `<span class='price'>${price}</span>` : ``;
//                     itemEl.href = permalink;
//                     container.appendChild(itemEl);
//                 });

//                 this.resultsContainer.appendChild(container);
//             }
//         });

//         this.hideLoader();
//     }

//     nothingFound() {
//         this.hideLoader();
//         const container = document.createElement('div');
//         const heading = document.createElement('h4');
//         heading.innerText = 'Brak wyników dla wpisanej frazy';
//         container.classList.add('nothing-found');
//         container.appendChild(heading);
//         this.resultsContainer.appendChild(container);
//     }

//     showLoader() {
//         this.loader.classList.add('show');
//     }

//     hideLoader() {
//         this.loader.classList.remove('show');
//     }
// }

// new SearchForm();
