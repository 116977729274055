
  const ACTIVE_CLASS = 'FilterSidebarOverlay--active';
  const toggleFilterButtons = document.querySelectorAll('.toggle-filters');
  const filters = document.querySelector('.FilterSidebarOverlay');

  const toggleFilters = (ev) => {
    ev.preventDefault();
    filters.classList.toggle(ACTIVE_CLASS);
  };

  toggleFilterButtons?.forEach((button) => button.addEventListener('click', toggleFilters));
