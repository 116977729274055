const STICKY_CLASS = 'Header--sticky';
const headerOffset = 50;
const header = document.querySelector('.Header');

const toggleSticky = () => {
    window.scrollY > headerOffset
        ? header.classList.add(STICKY_CLASS)
        : header.classList.remove(STICKY_CLASS);
};

window.addEventListener('scroll', toggleSticky);



document.addEventListener('DOMContentLoaded', function () {
    const panels = document.querySelectorAll('.promotion_panels .panel');
    let currentIndex = 0;

    function showNextPanel() {
        // Ukryj aktualny panel
        panels[currentIndex].classList.remove('active');

        // Przejdź do następnego panelu
        currentIndex = (currentIndex + 1) % panels.length;

        // Pokaż nowy aktualny panel
        panels[currentIndex].classList.add('active');
    }

    if (window.innerWidth <= 1160) {
        // Pokaz pierwszy panel
        panels[currentIndex].classList.add('active');

        setInterval(showNextPanel, 5000); // Zmienia panele co 5 sekund
    }
});
