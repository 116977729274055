import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

document.querySelectorAll('.products-slider').forEach((slider, index) => {
    const uniqueId = `swiper-${index + 1}`;
    const paginationId = `pagination-${index + 1}`;
    const nextBtnId = `next-btn-${index + 1}`;
    const prevBtnId = `prev-btn-${index + 1}`;

    // Assign unique IDs
    slider.querySelector('.swiper-pagination').id = paginationId;
    slider.querySelector('.swiper-next-btn').id = nextBtnId;
    slider.querySelector('.swiper-prev-btn').id = prevBtnId;

    // Initialize Swiper
    const swiper = new Swiper(slider.querySelector('.swiper'), {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        speed: 700,
        spaceBetween: 32,
        slidesPerView: 1,
        breakpoints: {
            550: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: slider.getAttribute('data-columns'),
            },
        },
        // autoplay: {
        //     delay: 3500,
        //     disableOnInteraction: false,
        //     pauseOnMouseEnter: true,
        // },
        // pagination: {
        //     el: `#${paginationId}`,
        //     type: 'custom',
        //     renderCustom: (swiper, current, total) => {
        //         return `<span class="swiper-pagination-current">0${current}</span><span>0${total}</span>`;
        //     },
        // },
        navigation: {
            nextEl: `#${nextBtnId}`,
            prevEl: `#${prevBtnId}`,
        },
    });
});
